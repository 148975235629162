import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | 🔥 The Mige Myi Sports Pub 🍺
			</title>
			<meta name={"description"} content={"Step into a fusion of rustic charm and sporting passion."} />
			<meta property={"og:title"} content={"Home | 🔥 The Mige Myi Sports Pub 🍺"} />
			<meta property={"og:description"} content={"Step into a fusion of rustic charm and sporting passion."} />
			<link rel={"shortcut icon"} href={"https://migemyi.com/img/1748081.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://migemyi.com/img/1748081.png"} />
			<meta name={"msapplication-TileImage"} content={"https://migemyi.com/img/1748081.png"} />
			<meta name={"msapplication-TileColor"} content={"https://migemyi.com/img/1748081.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://migemyi.com/img/1.1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					The Mige Myi Sports Pub 🍺{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Step into a fusion of rustic charm and sporting passion.{"\n\n"}
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Book a Table
				</Link>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" background="--color-darkL2" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-2">
			<Text margin="0px 0px 20px 0px" font="--headline2" color="--light">
				🏰 Welcome to The Mige Myi! 🏰{"\n\n"}
			</Text>
			<Text
				margin="0px 500px 80px 0px"
				color="#bbc4cd"
				font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
				lg-margin="0px 0 80px 0px"
				md-margin="0px 0 50px 0px"
			>
				Housed within stone walls that echo tales of old, gather 'round the blazing fireplace to cheer for the modern gladiators on the pitch.{"\n\n"}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="40px 30px"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap="26px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="normal 500 20px/1.2 --fontFamily-sans">
							🛡️ Our Unique Features{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
							<Strong>
								{"\n"}Castle Walls & Big Screens:
							</Strong>
							{" "}Relive the medieval while staying rooted in the present.{"\n"}
							<br />
							<Strong>
								Torchlit Corners:
							</Strong>
							{" "}Perfect for those intense match discussions.{"\n"}
							<br />
							<Strong>
								Stone Benches & Wooden Stools:
							</Strong>
							{" "}A touch of antiquity for your game night.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="normal 500 20px/1.2 --fontFamily-sans">
							🍻 On Tap{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
							<Strong>
								{"\n"}Elixirs of the Game:
							</Strong>
							{" "}Traditional brews served in handcrafted goblets and mugs. Feel like a king or queen with every sip!{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="normal 500 20px/1.2 --fontFamily-sans">
							💡 Pub Facts{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
							The stone walls are inspired by iconic castles of yore.
Our fireplace is the heart of our pub, always welcoming, always warm.
Our uniforms? Think jerseys with a touch of chainmail.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="normal 500 20px/1.2 --fontFamily-sans">
							🎲 Medieval Sport-Themed Games{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
							Merge the ancient with the contemporary. Ever tried archery darts or catapult shuffleboard?{"\n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Image19 />
		<Section padding="60px 0" sm-padding="40px 0" color="--dark" background="--color-darkL2">
			<Text
				as="h1"
				font="--headline1"
				margin="0 0 24px 0"
				border-color="#F7FBFF"
				color="--light"
			>
				FAQs
			</Text>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0" color="--light">
							Is there life on Mars?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--light">
							It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something more. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0" color="--light">
							Why is the sky blue?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--light">
							It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something more. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0" color="--light">
							When will we get there?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--light">
							It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something more. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652e9dee47aa25001fb0714b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});